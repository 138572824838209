import React from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, Col, NavDropdown, Row } from "react-bootstrap";
import SearchBox from "../SearchBox";
import { logout } from "../../actions/userActions";

import YuriesoftLogoWhite from "../../assets/yuriesoft-logo-white.png";
import * as PropTypes from "prop-types";

import "./header.styles.css";

function Box(props) {
  return null;
}

Box.propTypes = { children: PropTypes.node };
const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar
        className="navbar-container"
        bg="dark"
        variant="dark"
        expand="lg"
        collapseOnSelect
      >
        <Container>
          <div className="logo-burger-container">
            <LinkContainer to="/">
              <Navbar.Brand className="d-flex flex-row  align-items-center">
                <img height={30} src={YuriesoftLogoWhite} alt="logo" />{" "}
                <div style={{ marginLeft: "10px" }}>
                  <span> Shop</span>
                </div>
              </Navbar.Brand>
            </LinkContainer>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Row className="mt-3 mb-3">
                <SearchBox />
              </Row>
              <Nav className="ml-auto">
                <LinkContainer to="/cart">
                  <Nav.Link>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <i className="fas fa-shopping-cart"></i> Cart
                    </span>
                  </Nav.Link>
                </LinkContainer>
                {userInfo ? (
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link>
                      <i className="fas fa-user"></i> Sign In
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title="Admin" id="adminmenu">
                    <LinkContainer to="/admin/userlist">
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/productlist">
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/orderlist">
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
