import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { listTopProducts } from "../actions/productActions";
import { useDispatch, useSelector } from "react-redux";

const ProductCarousel = () => {
  const dispatch = useDispatch();
  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;

  useEffect(() => {
    dispatch(listTopProducts());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" className="bg-dark">
      {Array.isArray(products)
        ? products?.map((product) => (
            <Carousel.Item key={product._id}>
              <div>
                <Link
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  //   alignContent: "center",
                  //   width: "100%",
                  //   height: "100%",
                  // }}
                  to={`/product/${product._id}`}
                >
                  <Carousel.Caption className="carousel-caption">
                    <Image
                      style={{ marginTop: "20px", marginBottom: "10px" }}
                      src={product.image}
                      alt={product.name}
                      fluid
                    />
                    <h2>
                      {product.name} (${product.price})
                    </h2>
                  </Carousel.Caption>
                </Link>
              </div>
            </Carousel.Item>
          ))
        : null}
    </Carousel>
  );
};

export default ProductCarousel;
