import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/*<meta name="keyword" content={keyword} />*/}
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Yuriesoft Shop",
  // keywords: "electronics, buy electronics"
  description: "Only the best products",
};

export default Meta;
